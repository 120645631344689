import { AxiosResponse } from 'axios';
import { useCallback } from 'react';
import { Error as APIError } from '../model/Error';
import { useAxiosClient } from './useAxiosClient';

interface UseGoServices {
  getGenerateResult: (datasetId: number, datasetVersion: number, variant: number) => Promise<string[] | APIError>;
  getGoArtefact: (path: string) => Promise<string>;
}

interface Endpoint {
  endpoints: string[];
  warnings?: string[];
}

interface GoBuilderResponse {
  statusCode: number;
  message: Endpoint;
}

export const useGoServices = (): UseGoServices => {
  const create = useAxiosClient();

  const getGenerateResult = useCallback(
    async (datasetId: number, datasetVersion: number, variant: number): Promise<string[] | APIError> => {
      const client = create();

      const config = {
        headers: {
          'x-api-key': 'TeamRocket@gbgplc.com',
        },
      };
      const request: any = {
        datasetId: datasetId,
        datasetVersion: datasetVersion,
        variant: variant,
      };
      try {
        const result: AxiosResponse<GoBuilderResponse> = await client.post<GoBuilderResponse>(
          '/go/run-publish',
          request,
          config,
        );
        return result.data.message.endpoints.filter(Boolean).map(url => new URL(url).pathname);
      } catch (error: any) {
        if (error.response?.data) {
          return error.response.data;
        }
        return {
          problem: 'Unexpected error calling API',
          code: 99999,
          action: 'Contact Team Rocket',
          location: 'API Call',
        };
      }
    },
    [create],
  );

  const getGoArtefact = useCallback(
    async (path: string): Promise<string> => {
      const client = create();

      const config = {
        headers: {
          'x-api-key': 'TeamRocket@gbgplc.com',
        },
      };
      const result: AxiosResponse<string> = await client.get<string>(path, config);
      return result.data;
    },
    [create],
  );

  return { getGenerateResult, getGoArtefact };
};

export default useGoServices;
