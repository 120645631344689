import { JsonEditor, JSONEditorPropsOptional, createJSONEditor } from 'vanilla-jsoneditor';
import { useEffect, useRef } from 'react';
import './JSONEditor.css';

function isNotNull(arg: any): arg is HTMLDivElement {
  return arg !== null;
}

const JSONEditor = (props: JSONEditorPropsOptional) => {
  const refContainer = useRef<HTMLDivElement | null>(null);
  const refEditor = useRef<JsonEditor | null>(null);

  useEffect(() => {
    const divContainer = refContainer.current;
    if (isNotNull(divContainer)) {
      refEditor.current = createJSONEditor({
        target: divContainer,
        props,
      });
    }

    return () => {
      if (refEditor.current) {
        refEditor.current.destroy();
        refEditor.current = null;
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (refEditor.current) {
      refEditor.current.updateProps(props);
    }
  }, [props]);

  return <div className="svelte-jsoneditor-react" ref={refContainer}></div>;
};

export default JSONEditor;
