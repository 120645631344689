import './ValidationSchemaForm.css';
import { useMemo, useState } from 'react';
import { Error } from '../../../model/Error';
import JSONEditor from '../../../components/jsonEditor/JSONEditor';
import { Content, TextContent, MenuItem, MenuButton } from 'vanilla-jsoneditor';
import { useValidate } from '../../../hooks/useValidate';
import { FormGroup, Label, Button, ButtonSize, ErrorText, ButtonKind, Textarea } from '@gbg/gbgcomponentlibrary_react';
import { Mode } from 'vanilla-jsoneditor';

function isTextContent(arg: any): arg is TextContent {
  return arg.text !== null;
}

function isMenuButtonItem(item: any): item is MenuButton {
  return item.icon !== undefined;
}

export interface ValidationSchemaFormProps {
  isAuthorised: boolean;
  schema: string;
  setSchema: (schema: string) => void;
  persist: (schema: string) => Promise<Error[] | undefined>;
}

const ValidationSchemaForm = ({ isAuthorised, schema, setSchema, persist }: ValidationSchemaFormProps) => {
  const [saveError, setSaveError] = useState<string>('');
  const [seeResultError, setSeeResultError] = useState<string>('');
  const { getValidateResult } = useValidate();
  const [sampleData, setSampleData] = useState<string>('');
  const [result, setResult] = useState<string>();

  const schemaContent = useMemo<Content>(() => {
    return {
      text: schema,
    };
  }, [schema]);
  const setSchemaContent = (content: Content) => {
    if (isTextContent(content)) {
      setSchema(content.text);
    }
  };

  const sampleContent = useMemo<Content>(() => {
    return {
      text: sampleData,
    };
  }, [sampleData]);
  const setSampleContent = (content: Content) => {
    if (isTextContent(content)) {
      setSampleData(content.text);
    }
  };

  const runValidationSchema = async () => {
    setResult('');
    setSeeResultError('');
    const [validFields, invalidFields] = validateFields(sampleData, schema);
    if (validFields) {
      const errors = await getValidateResult(schema, sampleData);
      if (errors.length > 0) {
        let output = '';
        let count = 0;
        errors.forEach(error => {
          output += `\n\n\t ${++count}. ${error.problem}`;
        });
        setResult(count + ' error(s):' + output);
        setSeeResultError('');
      } else {
        setSeeResultError('');
        setResult('Sample data is considered valid against the given JSON schema');
      }
    } else {
      setResult('');
      setSeeResultError(`These fields are invalid: ${invalidFields.join(', ')}`);
    }
  };

  const saveSchema = async () => {
    const errors = await persist(schema);
    if (errors) {
      setSaveError(errors.map((v, i) => `${v.problem}: ${v.action}`).join(', '));
    } else {
      setSaveError('');
    }
  };

  const getMenuItems = (items: MenuItem[]): MenuItem[] | undefined => {
    const toDisplay = [
      'jsoneditor-format',
      'jsoneditor-compact',
      'magnifying-glass',
      'arrow-rotate-left',
      'arrow-rotate-right',
    ];
    const menuItems: Array<MenuItem> = [];
    items.forEach(item => {
      if (isMenuButtonItem(item) && item.icon && toDisplay.includes(item.icon?.iconName)) {
        menuItems.push(item);
      }
    });
    return menuItems;
  };

  return (
    <>
      <FormGroup>
        <Label>Validation schema</Label>
        <JSONEditor
          data-testid="validation-schema"
          mode={Mode.text}
          content={schemaContent}
          onChange={setSchemaContent}
          readOnly={!isAuthorised}
          onRenderMenu={getMenuItems}
        />
      </FormGroup>
      <div>
        {isAuthorised && (
          <Button data-testid="save-validation-schema" onClick={saveSchema} size={ButtonSize.Small}>
            Save Validation Schema
          </Button>
        )}
        <ErrorText data-testid="save-error-text">{saveError}</ErrorText>
        <br />
      </div>
      <FormGroup>
        <Label>Sample data</Label>
        <JSONEditor
          data-testid="sample-data"
          mode={Mode.text}
          content={sampleContent}
          onChange={setSampleContent}
          onRenderMenu={getMenuItems}
        />
      </FormGroup>
      <div>
        <Button
          data-testid="result-button"
          kind={ButtonKind.Secondary}
          onClick={runValidationSchema}
          size={ButtonSize.Small}
        >
          See Result
        </Button>
      </div>
      <ErrorText data-testid="result-error-text">{seeResultError}</ErrorText>
      <br></br>
      {result && (
        <FormGroup>
          <Label>Result</Label>
          <Textarea data-testid="result" placeholder="" aria-label="Result" value={result} readOnly />
        </FormGroup>
      )}
    </>
  );
};

const validateFields = (sampleData: string, schema: string): [boolean, string[]] => {
  const invalidFields = [];
  if (!schema || !isValidJson(schema)) {
    invalidFields.push('Validation Schema');
  }
  if (!sampleData || !isValidJson(sampleData)) {
    invalidFields.push('Sample Data');
  }
  return [invalidFields.length === 0, invalidFields];
};

const isValidJson = (stringToCheck: string): boolean => {
  let valid = true;
  try {
    JSON.parse(stringToCheck);
  } catch (e) {
    valid = false;
  }
  return valid;
};

export default ValidationSchemaForm;
