import './EditDataset.css';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Dataset } from '../../../model/Dataset';
import { useDatasets } from '../../../hooks/useDatasets';
import { useSteps } from '../../../hooks/useSteps';
import DatasetForm from '../datasetForm/DatasetForm';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import TabBar from '../../../components/tabs/TabBar';
import { StepsTable } from '../../steps-table/StepsTable';
import { Step } from '../../../model/Step';
import ValidationSchemaForm from '../validationSchemaForm/ValidationSchemaForm';
import { useValidationSchema } from '../../../hooks/useValidationSchema';
import { ValidationSchema } from '../../../model/Schema';
import { Identity } from '../../../model/Identity';
import { isAuthorised } from '../../../services/Authorisation';
import { toPublish, toPublishGo, toUpdate } from '../../../model/RequiredClaims';
import DatasetWorkflow, { TestIds as WorkflowTestIds } from './DatasetWorkflow';
import {
  toast,
  ToastPosition,
  ToastType,
  Pill,
  TabPanel,
  Button,
  ErrorText,
  PillType,
} from '@gbg/gbgcomponentlibrary_react';
import DatasetGo from './DatasetGo';

export const TestIds = {
  STATUS_PILL: 'status-pill',
  ...WorkflowTestIds,
};

const getPillTypeForStatus = (status: string | undefined): PillType => {
  let pillType = PillType.Dark;
  if (status) {
    status = status.toLowerCase();
    switch (status) {
      case 'live':
        pillType = PillType.Success;
        break;
      case 'draft':
        pillType = PillType.Warn;
        break;
      case 'testing':
        pillType = PillType.Error;
        break;
      case 'approval':
        pillType = PillType.Info;
        break;
      case 'retired':
      default:
        pillType = PillType.Dark;
    }
  }
  return pillType;
};

function isDataset(arg: any): arg is Dataset {
  return arg.description != null;
}

function isSteps(arg: any[]): arg is Step[] {
  return arg[0].name != null;
}

function isValidationSchema(arg: any): arg is ValidationSchema {
  return arg.schema != null;
}

const newDataset = {
  id: null,
  version: null,
  description: 'Loading ...',
  country: '',
  provider: '',
  pci_handler: false,
  status: 'draft',
};
type EditDatasetParams = {
  id: string;
  version: string;
};

const EditDataset = ({ identity }: { identity: Identity }) => {
  const { id, version } = useParams<EditDatasetParams>();
  const navigate = useNavigate();
  const location = useLocation();

  const [loading, setLoading] = useState(true);
  const [dataset, setDataset] = useState<Dataset>(newDataset);
  const [datasetName, setDatasetName] = useState(newDataset.description);
  const [schema, setSchema] = useState('');
  const [steps, setSteps] = useState<Step[]>([]);
  const [detailsError, setDetailsError] = useState('');
  const [validationError, setValidationError] = useState('');
  const [stepsError, setStepsError] = useState('');
  const [tabIndex, setTabIndex] = useState<number>(0);
  const { updateDataset, getDataset } = useDatasets();
  const { getSteps } = useSteps();
  const { updateValidationSchema, getValidationSchema } = useValidationSchema();

  const authorisedToUpdate = useMemo(() => {
    const { authorised } = isAuthorised(identity, toUpdate);
    return authorised;
  }, [identity]);

  const authorisedToPublish = useMemo(() => {
    const { authorised } = isAuthorised(identity, toPublish);
    return authorised;
  }, [identity]);

  const authorisedToPublishGo = useMemo(() => {
    const { authorised } = isAuthorised(identity, toPublishGo);
    return authorised;
  }, [identity]);

  const isAuthorisedToApproveToTesting = useMemo(
    () =>
      authorisedToPublish &&
      dataset.status.toUpperCase() === 'SUBMITTED_TO_TESTING' &&
      dataset.lastUpdatedBy !== identity.username,
    [authorisedToPublish, dataset.status, dataset.lastUpdatedBy, identity.username],
  );
  const onSuccessfulWorkflowAction = useCallback(() => {
    navigate(`/datasets/${id}/versions/${version}`);
    setTabIndex(0);
  }, [id, version, navigate]);

  useEffect(() => {
    setLoading(true);
    let loadingDetails = true;
    let loadingValidationSchema = true;
    let loadingSteps = true;

    getDataset(Number(id), Number(version)).then(d => {
      if (isDataset(d)) {
        setDataset(d);
        setDatasetName(d.description);
      } else {
        setDetailsError(`Error calling Get Details: ${d.map((v, i) => `${v.problem}: ${v.action}`).join(', ')}`);
      }
      loadingDetails = false;
      if (!loadingValidationSchema && !loadingSteps) setLoading(false);
    });
    getValidationSchema(Number(id), Number(version)).then(s => {
      if (isValidationSchema(s)) {
        setSchema(s.schema);
      } else {
        setValidationError(
          `Error calling Get Validation Schema: ${s.map((v, i) => `${v.problem}: ${v.action}`).join(', ')}`,
        );
      }
      loadingValidationSchema = false;
      if (!loadingDetails && !loadingSteps) setLoading(false);
    });
    getSteps(Number(id), Number(version)).then(s => {
      if (s.length > 0) {
        if (isSteps(s)) {
          setSteps(s);
        } else {
          setStepsError(`Error calling Get Steps: ${s.map((v, i) => `${v.problem}: ${v.action}`).join(', ')}`);
        }
      }
      loadingSteps = false;
      if (!loadingDetails && !loadingValidationSchema) setLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.key]);

  const persistDetails = useCallback(
    async (id: number, version: number, name: string, country: string, provider: string, pci_handler: boolean) => {
      const errors = await updateDataset(id, version, name, country, provider, pci_handler);
      if (!errors) {
        setDatasetName(name);
        toast({
          title: '',
          content: `${name} Details has been saved`,
          position: ToastPosition.Top,
          type: ToastType.Success,
          duration: 3000,
          dismissable: false,
        });
      }
      return errors;
    },
    [updateDataset],
  );

  const persistSchema = useCallback(
    async (schema: string) => {
      const errors = await updateValidationSchema(Number(id), Number(version), schema);
      if (!errors) {
        toast({
          title: '',
          content: `${datasetName} Validation schema has been saved`,
          position: ToastPosition.Top,
          type: ToastType.Success,
          duration: 3000,
          dismissable: false,
        });
      }
      return errors;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [updateValidationSchema, datasetName],
  );

  const tabs = useMemo(() => {
    const tabList = ['Details', 'Validation schema', 'Steps', 'Workflow'];
    if (authorisedToPublishGo) {
      tabList.push('Go');
    }
    return tabList;
  }, [authorisedToPublishGo]);

  if (loading) {
    return <h1 data-test-id="edit-dataset-loading-text">Loading Dataset</h1>;
  } else {
    //TODO Revert back to using TabBar once changes have been integrated into the design GBG Component library (https://github.com/gbgplc-internal/GBG-Component-Library)
    return (
      <>
        <div>
          <div>
            <h1 data-testid="edit-dataset-header">{datasetName}</h1>
            <Pill data-testid="id-pill" type={PillType.Info}>
              ID {dataset.id}
            </Pill>
            <Pill data-testid="version-pill" type={PillType.Info}>
              VERSION {dataset.version}
            </Pill>
            <Pill data-testid={TestIds.STATUS_PILL} type={getPillTypeForStatus(dataset.status)}>
              {dataset.status.toUpperCase()}
            </Pill>
          </div>
        </div>
        <TabBar selectedIndex={tabIndex} onTabSelected={(i: number) => setTabIndex(i)} tabs={tabs} updateUrl={true} />
        <TabPanel selectedIndex={tabIndex} index={0}>
          <DatasetForm
            dataset={dataset}
            setDataset={setDataset}
            isAuthorised={authorisedToUpdate}
            persist={persistDetails}
            visibleFields={[]}
          />
        </TabPanel>
        <TabPanel selectedIndex={tabIndex} index={1}>
          <ValidationSchemaForm
            isAuthorised={authorisedToUpdate && dataset.status.toLowerCase() === 'draft'}
            schema={schema}
            setSchema={setSchema}
            persist={persistSchema}
          />
        </TabPanel>
        <TabPanel selectedIndex={tabIndex} index={2}>
          <StepsTable
            isAuthorised={authorisedToUpdate && dataset.status.toLowerCase() === 'draft'}
            datasetId={dataset.id}
            datasetVersion={dataset.version}
            steps={steps}
          ></StepsTable>
          <br></br>
          {authorisedToUpdate && dataset.status.toLowerCase() === 'draft' ? (
            <Button
              data-testid="add-step-button"
              onClick={() => {
                navigate(`/datasets/${dataset.id}/versions/${dataset.version}/steps/add`);
              }}
            >
              Add a step
            </Button>
          ) : null}
        </TabPanel>
        <TabPanel selectedIndex={tabIndex} index={3}>
          <DatasetWorkflow
            id={Number(id)}
            version={Number(version)}
            status={dataset.status}
            authorisedToUpdate={authorisedToUpdate}
            authorisedToPublish={authorisedToPublish}
            onSuccessfulWorkflowAction={onSuccessfulWorkflowAction}
            lastUpdatedBy={dataset.lastUpdatedBy}
            authorisedToApproveToTesting={isAuthorisedToApproveToTesting}
          />
        </TabPanel>
        {authorisedToPublishGo && (
          <TabPanel selectedIndex={tabIndex} index={4}>
            <DatasetGo
              datasetId={Number(id)}
              datasetVersion={Number(version)}
              authorisedToPublishGo={authorisedToPublishGo}
            />
          </TabPanel>
        )}
        <ErrorText data-testid="retrieve-details-error-text">{detailsError}</ErrorText>
        <ErrorText data-testid="retrieve-schema-error-text">{validationError}</ErrorText>
        <ErrorText data-testid="retrieve-steps-error-text">{stepsError}</ErrorText>
      </>
    );
  }
};

export default EditDataset;
