import withConditionalRendering from '../../../components/withConditionalRendering';
import withApiCallHandling from '../../../components/withApiCallHandling';
import { useCallback, useState } from 'react';
import { useGoServices } from '../../../hooks/useGoServices';

import {
  ErrorText,
  Button,
  ButtonKind,
  ButtonSize,
  Accordion,
  AccordionItem as BaseAccordionItem,
} from '@gbg/gbgcomponentlibrary_react';

export const TestIds = {
  GENERATE_BUTTON: 'generate-button',
  RESULT_SUCCESS: 'result-success',
  RESULT_ERROR: 'result-error',
};

const ButtonWithApiCallHandling = withApiCallHandling(Button);
const ButtonWithApiCallHandlingIfVisible = withConditionalRendering(ButtonWithApiCallHandling);

interface DatasetGoProps {
  datasetId: number;
  datasetVersion: number;
  authorisedToPublishGo: boolean;
}

interface ClickableAccordionItemProps {
  header: string;
  onClick?: () => void;
  children: React.ReactNode;
}

// Workaround for the AccordionItem not able to raise events
const AccordionItem = ({ header, onClick, children }: ClickableAccordionItemProps) => {
  const handleClick = () => onClick?.();

  return (
    <div onClick={handleClick}>
      <BaseAccordionItem header={header}>{children}</BaseAccordionItem>
    </div>
  );
};

const HttpGetAccordianItem = ({ endpoint }: { endpoint: string }) => {
  const [data, setData] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const { getGoArtefact } = useGoServices();

  const handleClick = useCallback(async () => {
    if (data) return;

    setLoading(true);
    setError(null);

    try {
      const response = await getGoArtefact(endpoint);
      setData(response);
    } catch (err) {
      if (err instanceof Error) {
        console.error(err);
        setError(err.message);
      } else {
        setError('An unknown error occurred');
      }
    } finally {
      setLoading(false);
    }
  }, [data, endpoint, getGoArtefact]);

  return (
    <AccordionItem header={endpoint} onClick={handleClick}>
      {loading && <p>Loading...</p>}
      {error && <p>Error: {error}</p>}
      {data && <pre>{typeof data === 'object' ? JSON.stringify(data, null, 2) : data}</pre>}
    </AccordionItem>
  );
};

const DatasetGo = ({ datasetId, datasetVersion, authorisedToPublishGo }: DatasetGoProps) => {
  const [error, setError] = useState<string[]>();
  const [result, setResult] = useState<string[]>();
  const { getGenerateResult } = useGoServices();

  const runGenerate = async () => {
    setResult([]);
    setError([]);
    // TODO: Variant version hardcoded to 1 at the moment!
    const variant: number = 1;
    const apiResult = await getGenerateResult(datasetId, datasetVersion, variant);
    if (Array.isArray(apiResult) && apiResult.every((item: any) => item instanceof Object && 'problem' in item)) {
      setError(apiResult.map((e: any) => e.problem));
      return;
    }
    setResult((apiResult as string[]).map(e => e.replace('/v1', '')));
  };

  return (
    <>
      <h2>Go Publish Tab</h2>
      <div>
        <ButtonWithApiCallHandlingIfVisible
          visible={authorisedToPublishGo}
          data-testid={TestIds.GENERATE_BUTTON}
          call={runGenerate}
          onSuccess={() => result}
          onFailure={() => error}
          successMessage="Generation successful"
          kind={ButtonKind.Secondary}
          size={ButtonSize.Small}
          className={'button-margin'}
          disabled={!authorisedToPublishGo}
        >
          Generate
        </ButtonWithApiCallHandlingIfVisible>
      </div>
      <br></br>
      {result && result.length > 0 && (
        <>
          <Accordion alwaysOpen={true} data-testid={TestIds.RESULT_SUCCESS}>
            {result.map(endpoint => (
              <HttpGetAccordianItem key={endpoint} endpoint={endpoint} />
            ))}
          </Accordion>
        </>
      )}
      {error && error.length > 0 && (
        <div data-testid={TestIds.RESULT_ERROR}>
          {error.map((e, i) => (
            <ErrorText key={i}>{e}</ErrorText>
          ))}
        </div>
      )}
    </>
  );
};

export default DatasetGo;
